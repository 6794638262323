import React, { useState, useEffect } from 'react';
import { Grid, Box, Dialog, Paper } from '@mui/material';
import client from './client';

export default function NotesModal({ open, transcriptId, handleClose }) {
  const [notes, setNotes] = useState([])
  
  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const notes = await client.fetchNotes(transcriptId)
        setNotes(notes)
      } catch(e) {
        alert("Error fetching notes")
      }
    }
    fetchNotes()
  }, [transcriptId])
  return (
    <Dialog 
      fullScreen
      onClose={handleClose}
      open={open} 
    >
      <>
      {notes.length > 0 ? (
        <>
          {notes.map(note => (
            <Box sx={{ borderTop: "1px solid black", padding: 16, width: "100%", display: "flex", justifyContent: "center" }} key={note.id}>
              <Paper style={{ padding: 32 }}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12}>
                    <div style={{ fontWeight: "bold" }}>Note: Written by {note.user_id} </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>{note.content}</div>
                  </Grid>
                </Grid> 
              </Paper>
            </Box>
          ))}
        </>
      ) : ( 
        <>
          <div>No notes yet</div>
        </>
      )}
      </>
    </Dialog>
  )
}