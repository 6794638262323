import React, { useState, useEffect } from 'react';

import './App.css';
import client from './client';
import Login from './Login';
import Main from './Main';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

export async function loader(params) {
  if (params.params.transcriptId) {
    const transcript = await getTranscript(params.params.transcriptId) 
    return { transcript } 
  }
}

export async function getTranscript(transcriptId) {
  const transcript = await client.fetchTranscript(transcriptId)
  return transcript
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />
  },
  {
    path: "/:transcriptId",
    element: <Main />
  }
])


function App() {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const loginUser = async () => {
      try {
        const currentUser = await client.fetchCurrentUser()
        if (currentUser) {
          setLoggedIn(true)
          setLoading(false)
        } else {
          setLoggedIn(false)
          setLoading(false)
        }
      } catch(e) {
        console.log('not logged in')
      }
    }
    loginUser()
  }, [loggedIn])

  return (
    <>
      {loggedIn ? (
        <div className="App">
          <div className="container" style={{ display: "flex", maxWidth: "100vw" }}>
            { loading ? (
              <h1>Loading</h1>
            ) : (
              <>
                <RouterProvider router={router} />
              </>
            )}
          </div>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
