import axios from 'axios'
import qs from 'qs'

const headers = {
  'Content-Type': 'application/json'
}

let authToken = null;
if (typeof localStorage !== 'undefined') {
  authToken = localStorage.getItem('jwt_access_token')
}

if (!!authToken) {
  headers['Authorization-Token'] = authToken
}

const v1 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
})

let client = {
  login: (email, password) => v1.post(`/sessions`, { user: { email, password  } }).then(res => res.data),
  fetchCurrentUser: () => v1.get(`/sessions/me`).then(res => res.data),
  fetchTranscripts: () => v1.get(`/transcripts`).then(res => res.data),
  fetchTranscript: (id) => v1.get(`/transcripts/${id}`).then(res => res.data),
  fetchNotes: (transcriptId) => v1.get(`/transcripts/${transcriptId}/notes`).then(res => res.data),
  createNote: (transcriptId, content) => v1.post(`/transcripts/${transcriptId}/notes`, { note: { content: content }}).then(res => res.data),
  updateNote: (transcriptId, ratingReason, rating) => v1.patch(`/transcripts/${transcriptId}`, { transcript: { rating_reason: ratingReason, rating: rating }}).then(res => res.data),
}

export default client;
