import React, { useState, useEffect } from 'react';
import { Select, MenuItem, TextField, Grid, Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, PaperProps } from '@mui/material';
import client from './client';
import Draggable from 'react-draggable'

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function AddNoteModal({ open, transcriptId, handleClose }) {
  const [content, setContent] = useState('')
  const [ratingReason, setRatingReason] = useState('')
  const [rating, setRating] = useState(0)

  const saveNote = async () => {
    try {
      const note = await client.createNote(transcriptId, content)
      const transcript = await client.updateNote(transcriptId, ratingReason, rating)
      alert("Note saved")
      handleClose()
    } catch (e) {
      alert("Error saving note")
    }
  }

  const handleChange = (e) => {
    setContent(e.target.value)
  }

  const handleChangeRating = (e) => {
    setRating(e.target.value)
  }

  const handleChangeRatingReason = (e) => {
    setRatingReason(e.target.value)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={"md"}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Add Notes for this transcript below
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          
        </DialogContentText>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              autoFocus
              id="content"
              label="Notes"
              type="text"
              fullWidth
              onChange={handleChange}
              value={content}
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} container direction="row">
            <Grid item xs={6}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rating}
                label="Rating"
                onChange={handleChangeRating}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                autoFocus
                id="ratingReason"
                label="Rating Reason"
                type="text"
                fullWidth
                onChange={handleChangeRatingReason}
                value={ratingReason}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={saveNote}>Save Notes</Button>
      </DialogActions>
    </Dialog>
  )
}