import React, { useState, useEffect } from 'react';
import client from './client'
import NotesModal from './NotesModal';
import AddNoteModal from './AddNoteModal';
import { useNavigate, useParams } from 'react-router-dom';

export default function Main() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [transcripts, setTranscripts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false)
  const [showAddNoteModal, setShowAddNoteModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const { transcriptId } = useParams();

  useEffect(() => {
    const index = transcripts.findIndex(transcript => transcript.id === transcriptId);
    if (index !== -1) {
      setCurrentIndex(index);
    }
  }, [transcripts, transcriptId]);

  useEffect(() => {
    const loginUser = async () => {
      try {
        const currentUser = await client.fetchCurrentUser()
        if (currentUser) {
          setLoggedIn(true)
        } else {
          setLoggedIn(false)
        }
      } catch(e) {
        console.log('not logged in')
      }
    }
    loginUser()
  }, [loggedIn])

  useEffect(() => {
    const fetchTranscripts = async () => {
      try {
        const transcripts = await client.fetchTranscripts()
        setTranscripts(transcripts)
        setLoading(false) 
        if (!transcriptId) {
          navigate(`/${transcripts[0].id}`)
        }
      } catch(e) {
        console.log(e)
        alert("Error fetching transcripts")
      }
    }
    if (loggedIn) {
      fetchTranscripts()
    }
  }, [loggedIn])

  const nextFile = () => {
    const newIndex = currentIndex + 1;
    if (newIndex < transcripts.length) {
      setCurrentIndex(newIndex);
      navigate(`/${transcripts[newIndex].id}`)
    }
  }

  const previousFile = () => {
    const newIndex = currentIndex - 1;
    if (newIndex >= 0) {
      setCurrentIndex(newIndex);
      navigate(`/${transcripts[newIndex].id}`)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      // event.preventDefault();
      switch(event.key) {
        case "ArrowRight":
          event.preventDefault()
          nextFile();
          break;
        case "ArrowLeft":
          event.preventDefault()
          previousFile();
          break;
        default: 
          
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    }    
  }, [currentIndex])

  return (
    <>
      {transcripts && transcripts.length > 0 ? (
        <>
          <div id="fileViewer" style={{ paddingLeft: 16, flex: 2, borderRight: "1px solid black", overflowX: "auto", marginBottom: 32 }}>
            <h2>File Viewer: {transcripts[currentIndex].filename}</h2>
            <pre style={{ textWrap: "wrap" }}>{transcripts[currentIndex].content}</pre>
          </div>
          <div id="jsonViewer" style={{ paddingLeft: 16, flex: 1, overflowX: "auto", borderRight: "1px solid black" }}>
            <h2>Original GPT4 Generated JSON </h2>
            <pre style={{ textWrap: "wrap"}}>{JSON.stringify(transcripts[currentIndex].gpt4_response, null, 2)}</pre>
          </div>
          <div id="jsonViewer2" style={{ paddingLeft: 16, flex: 1, overflowX: "auto" }}>
            <h2>New: GPT4 Generated JSON</h2>
            <pre style={{ textWrap: "wrap" }}>{JSON.stringify(transcripts[currentIndex].updated_gpt4_response, null, 2)}</pre>
          </div>
          <div style={{ borderTop: "1px solid black", backgroundColor: "white", padding: 16, position: 'absolute', bottom: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <button style={{ marginRight: "10px" }} onClick={() => { setShowModal(true) }}>
              {showModal ? "Hide Notes" : "Show Notes "}
            </button>
            <button style={{ marginRight: "10px" }} onClick={previousFile}>Previous</button>
            <button onClick={nextFile}>Next</button>
            <button style={{ marginLeft: "10px" }}onClick={() => setShowAddNoteModal(true)}>Add Note</button>
          </div>
          <NotesModal open={showModal} transcriptId={transcripts[currentIndex].id} handleClose={() => { setShowModal(false) }} />
          <AddNoteModal open={showAddNoteModal} transcriptId={transcripts[currentIndex].id} handleClose={() => { setShowAddNoteModal(false) }} />
        </>
      ) : (
        <h1>No files found</h1>
      )}
    </>
  )
}